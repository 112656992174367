export function objToUri(params: any): string {
  return Object.keys(params).filter(v=>v!=='after')
    .map((value) => `${value}=${encodeURIComponent(params[value])}`)
    .join("&") + `&after=${btoa(JSON.stringify(params.after))}`;
}

export const loadScript = (src: string, callback: () => void) => {
  const scriptElement = document.createElement("script");

  scriptElement.type = "text/javascript";
  scriptElement.src = src;
  scriptElement.defer = true;

  scriptElement.onload = () => {
    callback();
  };

  document.head.appendChild(scriptElement);
};

export const loadScriptNew = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve(); // Évite d'ajouter plusieurs fois le même script
      return;
    }

    const scriptElement = document.createElement("script");
    scriptElement.type = "text/javascript";
    scriptElement.src = src;
    scriptElement.defer = true;

    scriptElement.onload = () => resolve();
    scriptElement.onerror = () => reject(new Error(`Failed to load script: ${src}`));

    document.head.appendChild(scriptElement);
  });
};


//is cn
export function isCn(): boolean {
  return location.hostname.includes(".cn");
}

export const getCookie = (cname: string) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
export const loadScripts = (srcArr: string[]) => {
  return Promise.allSettled(
    srcArr.map((src) =>
      loadScript(src, () => {
        //
      })
    )
  );
};

export const generateRandomPasswordComplicated = (): string => {
  const possibleL = "abcdefghijklmnopqrstuvwxyz";
  const possibleU = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const possibleN = "0123456789";
  let text = "";
  for (let i = 0; i < 5; i++) {
    text += possibleL.charAt(Math.floor(Math.random() * possibleL.length));
  }
  for (let i = 0; i < 5; i++) {
    text += possibleN.charAt(Math.floor(Math.random() * possibleN.length));
  }
  for (let i = 0; i < 5; i++) {
    text += possibleU.charAt(Math.floor(Math.random() * possibleU.length));
  }
  // shuffle
  return text
    .split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("");
};

export const retry = (
  fn: any,
  retriesLeft = 5,
  interval = 250
): Promise<any> => {
  return new Promise((resolve) => {
    (async () => {
      try {
        const res = await fn();
        return resolve(res);
      } catch (e) {
        await new Promise((r) => setTimeout(r, interval));
        if (retriesLeft === 1)
          console.error(e), console.error("Rejected after 5 retries!");
        retry(fn, retriesLeft - 1, interval);
      }
    })();
  });
};
