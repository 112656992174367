/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import type { AppState } from "../store";
// import { createSelector, OutputSelector } from "reselect";
import { createSelector, type OutputSelector } from "@reduxjs/toolkit";

import type { Customer } from "../../types/customer";
import type { CustomersSubscriptionPlan } from "./account.interfaces";

export const selectLanguage: OutputSelector<any, any, any> = createSelector(
	(state: AppState) => state,
	(state: any) => state.account.language,
);

export const selectIsUserLogged: OutputSelector<any, any, any> = createSelector(
	(state: AppState) => state,
	(state: any) => (state.account.isLogged ? true : false),
);

export const selectIsReseller: OutputSelector<any, any, any> = createSelector(
	(state: AppState) => state,
	(state: any) =>
		state.account.customer?.customer_type == 4 ||
		state.account.customer?.customer_type == 6 ||
		state.account.customer?.customer_type == 7,
);

export const selectIsResellerChild: OutputSelector<any, any, any> =
	createSelector(
		(state: AppState) => state,
		(state: any) =>
			state.account.customer?.customer_type == 1 &&
			state.account.customer?.affiliate_id != 0,
	);

export const selectIsKycApproved: OutputSelector<any, any, any> =
	createSelector(
		(state: AppState) => state,
		(state: any) => state.account.customer?.is_kyc === 1,
	);

export const selectIsBillingConfirmed: OutputSelector<any, any, any> =
	createSelector(
		(state: AppState) => state,
		(state: any) =>
			state.account.customer?.is_billing_info_confirmed == 1 ||
			state.account.customer?.affiliate_id != 0,
	);

export const selectAllActivePlans: OutputSelector<any, any, any> =
	createSelector(
		(state: AppState) => state,
		(state: any) => state.account.activePlan,
	);

export const selectAllPlans: OutputSelector<any, any, any> = createSelector(
	(state: CustomersSubscriptionPlan) => state,
	(state: any) => {
		return state.account.allPlans;
	},
);

export const selectHasStatesAndCities: OutputSelector<any, any, any> =
	createSelector(
		(state: AppState) => state,
		(state: any) =>
			state.account.customer.custFeatures?.some((x: any) =>
				[11, 12, 13, 14].includes(x),
			),
	);
export const selectTransactionId: OutputSelector<any, any, any> =
	createSelector(
		(state: AppState) => state,
		(state: AppState) => state.account.transactionId,
	);

export const selectIsAutoRenewal: OutputSelector<any, boolean, any> =
	createSelector(
		(state: AppState) => state,
		(state: AppState) => state.account.isAutoRenewal,
	);

export const selectUsername: OutputSelector<any, any, any> = createSelector(
	(state: AppState) => state,
	(state: any) => state.account.customer?.customer_name,
);
export const selectUserImage: OutputSelector<any, any, any> = createSelector(
	(state: AppState) => state,
	(state: any) => state.account.image,
);
export const selectUserPlan: OutputSelector<any, any, any> = createSelector(
	(state: AppState) => state,
	(state: any) => state.account.plan,
);

export const selectUserActivePlan: OutputSelector<any, any, any> =
	createSelector(
		(state: AppState) => state,
		(state: any) => state.account.activePlan,
	);

export const selectCustomer: OutputSelector<any, Customer, any> =
	createSelector(
		(state: AppState) => state,
		(state: any) => {
			if (state?.account?.customer) {
				return state!.account!.customer as Customer;
			}
			throw new Error("Customer not loaded");
		},
	);

export const selectBillingParent: OutputSelector<any, any, any> =
	createSelector(
		(state: AppState) => state,
		(state: any) => state.account.customer?.billing_parent_id,
	);

export const selectPromoCodeStep: OutputSelector<any, number, any> =
	createSelector(
		(state: AppState) => state,
		(state: AppState) => state.account.promoCodeStep,
	);

export const selectCheckedServiceOrder: OutputSelector<any, any, any> =
	createSelector(
		(state: AppState) => state,
		(state: any) => {
			return state.account.isCheckedServiceOrder;
		},
	);

export const selectDatesServiceOrder: OutputSelector<any, any, any> =
	createSelector(
		(state: AppState) => state,
		(state: any) => {
			return state.account.serviceOrderDates;
		},
	);
export const selectIdServiceOrder: OutputSelector<any, any, any> =
	createSelector(
		(state: AppState) => state,
		(state: any) => {
			return state.account.serviceOrderId;
		},
	);

export const selectProductTypes: OutputSelector<any, any, any> = createSelector(
	(state: AppState) => state,
	(state: any) => state.account.productTypes,
);
