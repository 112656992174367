// import { toast } from "material-react-toastify";
import { toast } from "react-hot-toast";
import $axios from "../utils/axios";
import { objToUri } from "../utils/general";
interface CompanyKYCData {
  customer_id: number;
  customer_type: string | number;
  company_name: string;
  company_website: string;
  business_email: string;
  company_address: string;
  phoneSkype: string;
  is_freelancer: number;
  company_size: string;
  targetCountries: string;
  targetSites: string;
  providerAndBandwidth: string;
  useCaseDescription: string;
}
interface CustomerKYCData {
  customer_id: number;
  customer_type: string | number;
  fullname: string;
  address: string;
  phoneSkype: string;
  is_freelancer: number;
  passport: File[];
  targetCountries: string;
  targetSites: string;
  providerAndBandwidth: string;
  useCaseDescription: string;
}

export interface PaddleInvoiceResponse {
  url: string;
  file: string;
}
type UpdateKycData = CustomerKYCData | CompanyKYCData;

class CustomerService {
  baseUrl: string;
  custId: number;
  constructor(custId: number) {
    this.custId = custId;
    this.baseUrl = `/customers`;
  }

  async get(): Promise<any> {
    const customer = await $axios.get(`${this.baseUrl}/${this.custId}`);
    return customer.data;
  }
  async paddleInvoice(
    package_uid: string,
    promo_code: string
  ): Promise<PaddleInvoiceResponse | undefined> {
    const customer = await $axios.post<any, any>(
      `${this.baseUrl}/${this.custId}/paddle-invoice`,
      { package_uid, promo_code }
    );
    if (customer.data.data)
      return customer.data.data as unknown as PaddleInvoiceResponse;
    // toast.error(customer.data.message);
  }
  async update(data: any): Promise<any> {
    const customer = await $axios.put(`${this.baseUrl}/${this.custId}`, {
      customer: data,
    });
    return customer.data;
  }

  async updateKyc(data: UpdateKycData): Promise<any> {
    const customer = await $axios.put(`${this.baseUrl}/${this.custId}/kyc`, {
      customer: data,
    });
    return customer.data;
  }

  getServiceOrder = async (serviceOrderId: any) => {
    const r = await $axios.get(
      `${this.baseUrl}/${this.custId}/service-order?id=${serviceOrderId}`
    );
    if (r.data.success) return r.data.data;
  };

  async createServiceOrder(data: any) {
    const response = await $axios.post(
      `${this.baseUrl}/${this.custId}/service-order`,
      data
    );
    if (response.data.success) return response.data.data.id;
    toast.error("error occured");
  }

  static async isExists(data: any): Promise<any> {
    const customer = await $axios.get(
      `/register/exists?${Object.keys(data).reduce((acc, val) => {
        return `${acc}${val}=${data[val]}&`;
      }, "")}`
    );
    return customer.data;
  }
  static async isValidPostalCode(
    country: string,
    postalCode: string,
    reToken?: string
  ): Promise<any> {
    const customer = await $axios.post(`/register/postal-code`, {
      postalCode,
      country,
      reToken,
    });
    return customer.data;
  }
  static async isValidVatNumber(
    country: string,
    vatNumber: string,
    reToken?: string
  ): Promise<any> {
    const url = `/register/vat-number`;

    const { data } = await $axios.post(url, { vatNumber, country, reToken });

    return data.success;
  }
  static async isDisposable(data: any): Promise<any> {
    const customer = await $axios.get(
      `/register/disposable?${Object.keys(data).reduce((acc, val) => {
        return `${acc}${val}=${data[val]}&`;
      }, "")}`
    );
    return customer.data;
  }

  async promptOtp() {
    const resp = await $axios.get(`/customers/${this.custId}/otp`);
    return resp;
  }
  async verifyOtp(otp: number) {
    const resp = await $axios.post(`/customers/${this.custId}/otp`, { otp });
    const data: any = resp.data;
    return data.success;
  }
  async getResellers(): Promise<any> {
    const resellers = await $axios.get(`/customers/${this.custId}/resellers/`);
    return resellers.data;
  }

  async getSubUsers(): Promise<any> {
    const resellers = await $axios.get(`/customers/${this.custId}/sub-users/`);
    return resellers.data;
  }
  async getActivePlans(): Promise<any> {
    const plans = await $axios.get(`/customers/${this.custId}/active-plans/`);
    return plans.data;
  }

  async getAllWhiteIps(): Promise<any> {
    const ips = await $axios.get(`/customers/${this.custId}/ips`);
    return ips.data;
  }

  async addwhiteIps(ip: string): Promise<any> {
    const ips = await $axios.post(`/customers/${this.custId}/ips`, { ip });
    return ips.data;
  }

  async removewhiteIps(ip: string): Promise<any> {
    const ips = await $axios.delete(`/customers/${this.custId}/ips/${ip}`);
    return ips.data;
  }

  async getCustomerMessages(plans: string[]): Promise<any> {
    let lang = JSON.parse(localStorage.getItem("redux") as any)?.account
      .language;
    if (lang.includes("zh")) {
      lang = 1;
    } else if (lang.includes("ru")) {
      lang = 3;
    } else {
      lang = 1;
    }
    const subUsers = await $axios.get(
      `/customers/${this.custId}/messages?${objToUri({ plans })}&lang=${lang}`
    );
    return subUsers.data;
  }
  async getAllPlans(): Promise<any> {
    const subUsers = await $axios.get(`/customers/${this.custId}/all-plans/`);
    return subUsers.data;
  }

  async getCustomerProductTypes(): Promise<{ name: string, id: number }[]> {
    const subUsers = await $axios.get(`/customers/${this.custId}/customer-product-types/`);
    return subUsers.data;
  }

  async getLastPayment(): Promise<any> {
    const payment = await $axios.get(
      `/customers/${this.custId}/payments?limit=1&order=descs`
    );
    return payment.data;
  }
  async impersonate(id: number, method = "id"): Promise<any> {
    
    const subUsers = await $axios.get(
      `/customers/${this.custId}/impersonate/${id}?method=${method}`
    );
    return subUsers.data;
  }
  async uploadFiles(files: File[]): Promise<boolean> {
    const body = files.reduce((formdata: any, file: File) => {
      formdata.append("files", file);
      return formdata;
    }, new FormData());
    const { data } = await $axios.post(`register/upload`, body, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return data.success;
  }

  async convert() {
    const endpoint = `/customers/${this.custId}/convert`;
    const r = await $axios.post(endpoint);
    return r.data as any;
  }

  async updateBillingData(data: any) {
    const endpoint = `/customers/${this.custId}/billing-data`;
    const r = await $axios.post(endpoint, data);
    return r.data;
  }

  async updateBillingInfoConfirmation(data: any) {
    const endpoint = `/customers/${this.custId}/billing-info-confirmation`;
    const r = await $axios.post(endpoint, data);
    return r.data;
  }

  async sendEmailToOwnerWhenAutoRenewalDisabled(): Promise<void> {
    const response = await $axios.post(
      `${this.baseUrl}/${this.custId}/disable-auto-renewal`
    );
    if (!response.data.success) toast.error(response.data.message);
  }
}

export default CustomerService;
